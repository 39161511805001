.modalWrapper {
    top: 50%;
    left: 50%;
    width: 25%;
    display: flex;
    outline: none;
    padding: 2.4rem;
    position: absolute;
    transform: translate(-50%, -50%);
    background: #ffffff;
    box-shadow: 0px 20px 24px -4px rgb(16 24 40 / 8%), 0px 8px 8px -4px rgb(16 24 40 / 3%);
    border-radius: 1.2rem;
    flex-direction: column;
  }
  @media screen and (min-width: 240px) and (max-width: 720px) {
    .modalWrapper {
      width: 70%;
    }
  }
  @media screen and (min-width: 720px) and (max-width:1024px) {
    .modalWrapper {
      width: 36%;
    }
  }

  