.mainContainer {
    width: 100%;
    height: 99%;
    background-color: white;
    /* width: 50%; */
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.1);
    flex: 1;
    overflow-y: hidden;
}

.header {
    padding: 3.2rem;
    background: #fff;
    border-bottom: 1px solid #eaecf0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer {
    padding: 1rem 3.2rem;
    background: #fff;
    border-top: 1px solid #eaecf0;
    display: flex;
    justify-content: flex-end;
    gap: 2rem;
}

.cardInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.halfDiv1 {
    width: 50%;
    display: flex;
    justify-content: center;
}

.halfDiv {
    width: 50%;
    display: flex;
    justify-content: space-around;
}

.swiperImg {
    width: 20rem;
    height: 15rem;
}

.videoCamera,
.capturedImg {
    width: 34rem;
}

.captureCard {
    padding: 0rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-top: 1rem; */
}

.cardTitle {
    font-size: 1.4rem;
    font-weight: 600;
    /* margin-bottom: 1rem; */
}

.fingerprintImg {
    width: 10rem;
    margin-bottom: 1rem;
}

.cameraIcon {
    position: absolute !important;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(255, 255, 255, 0.7) !important;
}

.btnText {
    font-weight: 500;
    font-size: 1rem;
    line-height: 2rem;
    vertical-align: middle;
    display: inline-block;
    margin: 0;
}

.button {
    padding: 0.5rem 1rem;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center
}

.buttonWrapper {
    margin-bottom: 0.5rem;
    border: 1px solid #D0D5DD;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 0.8rem;
    width: 100%;
    cursor: pointer;
    color: #ffffff;
    background-color: #7F56D9
}