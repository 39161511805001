.headerPDF {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 15px;
  }
  .headerPDF p {
    margin: 0;
  }
  .headerPDF .photoWrapper {
    display: flex;
    column-gap: 20px;
  }
  .headerPDF .photoWrapper img {
    width: 100%;
    height: 100%;
  }
  .headerPDF .photoWrapper > div {
    border: 1px dotted;
    width: 100px;
    height: 120px;
  }
  .headerPDF .leftHeader {
  }
  .headerPDF .leftHeader > div:first-child {
    display: flex;
    justify-content: space-between;
    column-gap: 25px;
  }
  .headerPDF .leftHeader > div:first-child > div:last-child {
    text-align: center;
  }
  .headerPDF table td {
    border: 1px solid;
    padding: 3px;
  }
  .headerPDF .leftHeader > div:last-child {
    font-size: 12px;
    font-weight: bold;
    margin-top: 5px;
  }
  
  /* *proforma1 */
  .proforma1Wrapper {
    margin-top: 5px;
  }
  .proforma1Wrapper p {
    margin: 0;
  }
  .proforma1Wrapper p:first-child {
  margin-bottom: 3px;
  }
  .proforma1Wrapper table td,th {
    border: 1px solid;
    padding: 3px;
    font-size: 8px;
  }
  .proforma1Wrapper table td:first-child {
    text-align: center;
  }
  
  /* * proforma2Wrapper */
  .proforma2Wrapper p {
    margin: 0;
    font-size: 8px;
  }
  .proforma2Wrapper > p:first-child {
    margin: 5px 0 0 0;
  }
  .proforma2Wrapper table {
    /* width: 98%; */
    /* margin: auto; */
  }
  .proforma2Wrapper td,.proforma2Wrapper th {
    border: 1px solid black;
    padding: 0 3px;
  }
  .proforma2Wrapper th {
    font-size: 8px;
  }
  .proforma2Wrapper td {
    font-size: 8px;
  }
  
  .colorWhiteText {
    color: #ffffff;
  }
  
  /* *Qualifying Seconds  */
  .qualifyingSecondsWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .qualifyingSecondsWrapper > p:first-child {
    margin: 30px 0px;
  }
  .qualifyingSecondsWrapper table td,.qualifyingSecondsWrapper th {
    border: 1px solid;
    padding: 3px;
    font-size: 14px;
    text-align: center;
    vertical-align: text-bottom;
  }
  
  
  /* * barCode  */
  .barcodeWrapper{
    display: flex;
    height: 40px;  
    justify-content: space-between;
  }
  .barcodeWrapper > div:first-child{
    height: 52px;
    display: flex;
    column-gap: 20px;
  }
  .barcodeWrapper > span > img{
    height: 100px;
  }
  .barcodeWrapper > div:first-child > img {
    height: 98%;
  }
  
  /* * For InService */
  .radioWrapper{
    display: flex;
    font-size: 8px;
    align-items: center;
    align-content: center;
    flex-direction: row;
    margin-top: 10px;
  }
  .radioWrapper > div {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-left: 10px;
  }
  .CheckBox{
    border: solid #000000 1px;
    padding: 3px;
    width: 15px;
    height: 15px;
    text-align: center;
    vertical-align: middle;
    margin-right: 5px;
  }