.mainContainer {
    /* width: 50%; */
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.1);
    flex: 1;
}

.header {
    padding: 1rem 3.2rem;
    background: #fff;
    border-bottom: 1px solid #eaecf0;
}

.footer {
    padding: 1rem 3.2rem;
    background: #fff;
    border-top: 1px solid #eaecf0;
}

.faceDiv {
    width: 75%;
}

.buttonWrapper {
    border: 1px solid #D0D5DD;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 0.8rem 0 0 0.8rem;
    width: 100%;
    cursor: pointer
}

.buttonWrapper1 {
    border: 1px solid #D0D5DD;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 0 0.8rem 0.8rem 0;
    width: 100%;
    cursor: pointer
}

.button {
    padding: 1rem 1.6rem;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.btnText {
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2rem;
    vertical-align: middle;
    display: inline-block;
    margin: 0;
}

.videoCamera {
    width: 100%;
    border-radius: 2rem;
}

.webcamDiv {
    border-radius: 2rem;
    border: 1rem solid white;
    margin-bottom: 1rem;
    position: relative;
    /* width: min(640px, 70%); */
}

.scanImg {
    width: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
}

.candidateInfo {
    text-align: left;
    padding: 1.5rem;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 1.2rem;
    position: absolute;
    bottom: 1.3rem;
    transform: translateX(-50%);
    left: 50%;
}

.verifiedWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.verifiedWrapper>img {
    margin-right: 1rem;
}

.candidateMatched {
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: #101828;
}

.flexDiv {
    display: flex;
    align-items: center;
}

.marginLeft {
    margin-left: 1.2rem;
}

.info1 {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2rem;
    color: #667085;
    width: 60%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.info2 {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2rem;
    color: #667085;
    width: 40%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}