.siteWrapper {
  padding: 3.2rem;
  background: #f9fafb;

  /* height: calc(100% - 6.4rem); */
  height: 100%;
  overflow: hidden;
}

.siteWrapper>div:first-child {
  background: rgb(255, 255, 255);
  box-shadow: 0px 4px 8px -2px rgb(16 24 40 / 10%), 0px 2px 4px -2px rgb(16 24 40 / 6%);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.pageWrapper {
  padding: 3.6rem;
  height: calc(87% - 7.2rem);
  overflow: auto;
}

.inputDiv,
.district,
.examCenter {
  margin-bottom: 2.6rem;
}

.examCenter,
.district {
  display: inline-block !important;
  width: 50%;
  vertical-align: top;
}

.inputDiv,
.buttonAndRadioWrapper {
  display: inline-block;
  width: calc(50% - 2rem);
  margin-right: 2rem;
}

.statusRadioWrapper {
  display: inline-block;
  width: 100%;
}

.inputDiv>label:first-child {
  font-size: 1.5rem;
}

.district>label:first-child {
  display: inline-block;
  font-size: 1.5rem;
  margin-left: 2rem;
}

.examCenter>label:first-child {
  display: inline-block !important;
  font-size: 1.5rem;
}

.wrapperVenuDistrict {
  width: 100% !important;
}

.formSubmittedText {
  display: inline-block;
  color: #667085;
  font-size: 1.4rem;
  font-family: 'Inter-Medium';
  margin-right: 1rem;
}

/* *upper Form Wrapper 📄📜 */
.formAndPhotoWrapper,
.formWrapper,
.CaptureLivePhoto {
  display: flex;
  column-gap: 1.2rem;
  margin-top: 2rem;
}

.formAndPhotoWrapper>div {
  vertical-align: middle;
}

.borderNone {
  border: none;
}

.formWrapper {
  justify-content: space-between;
  /* border-bottom: 0.2rem solid #eaecf0; */
  margin-top: 0rem;
}

.upperFormWrapper {
  /* display: flex;
  flex-direction: row;
  column-gap: 4rem;
  flex-wrap: wrap; */
  display: inline-block;
  width: 100%;
}

.CaptureLivePhoto {
  display: flex;
  flex-direction: row;
  column-gap: 3rem;
  flex-wrap: wrap;
}

.upperFormWrapper>div {
  /* width: 30%; */
  width: 100%;
  display: inline-block;
  /* border-bottom: 0.2rem solid #eaecf0; */
}

.photosWrapper {
  width: 20%;
}

.photosWrapper>div:first-child {
  display: flex;

  flex-direction: row;
  column-gap: 10px;
}

.photosWrapper>div:first-child>img {
  width: 152px;
  height: 144px;
}

.Url {
  cursor: pointer;
}

.photosWrapper .addPhoto {
  width: 152px;
  background: #00000021;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
}

.wrapperProfileImage {
  display: flex;

  column-gap: 2rem;
}

.ProfileImage {
  width: 12rem;
  height: 12rem !important;
  /* margin-bottom: 2rem; */
  object-fit: contain;
}

.livePhoto {
  width: 15rem;
  height: 15rem;
}

.photosWrapper>div:last-child>div:last-child {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 15px;
  column-gap: 1rem;
}

.photosWrapper>div:last-child>p:first-child {
  margin: 0;
  font-size: 1.5rem;
  margin-top: 1.5rem;
}

.photosWrapper>div:last-child>div:last-child>.captureContainer {
  width: 50%;
  height: 150px;
}

.photosWrapper>div:last-child>div:last-child>.captureContainer>img {
  width: 100%;
  height: 100%;
}

.photosWrapper>div:last-child>div:last-child>.captureContainer>div {
  background: #00000021;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
}

.photosWrapper>div:last-child>div:last-child>div {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 50%;
}

.photosWrapper>div:last-child>div:last-child>div>p {
  font-size: 1.5rem;
  cursor: pointer;
}

.formAndPhotoWrapper {
  flex-wrap: inherit;
  margin-bottom: 4rem;
}

.rightContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: 1rem;
  justify-content: flex-end;
}

.rightContainer>.idCardWrapper>div:last-child {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 15px;
  column-gap: 1rem;
}

.rightContainer>.idCardWrapper>p:first-child {
  margin: 0;
  font-size: 1.5rem;
  /* margin-top: 1.5rem; */
}

.rightContainer>.idCardWrapper>div:last-child>.captureContainer {
  /* width: 50%;
  height: 130px; */
}

.rightContainer>.idCardWrapper>div:last-child>.captureContainer>.imageContainer {
  position: relative;
}

.rightContainer>.idCardWrapper>div:last-child>.captureContainer>.imageContainer img {
  width: 152px;
  height: 144px;
}

.rightContainer>.idCardWrapper>div:last-child>.captureContainer>.imageContainer:hover .overlay {
  display: block;
  background: rgba(0, 0, 0, 0.3);
}

.rightContainer>.idCardWrapper>div:last-child>.captureContainer>.imageContainer:hover .reCaptureImage {
  opacity: 1;
}

.rightContainer>.idCardWrapper>div:last-child>.captureContainer>div {
  background: #00000021;
  width: 152px;
  height: 144px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
}

.rightContainer>.idCardWrapper>div:last-child>div {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  /* width: 15rem; */
}

.rightContainer>.idCardWrapper>div:last-child>div>p {
  font-size: 1.5rem;
  cursor: pointer;
}

.rightContainer>.idCardWrapper>div:last-child>.captureContainer>.imageContainer:hover .reCaptureImage {
  opacity: 1;
}

.realTimePhoto .imageContainer:hover .reCaptureImage {
  opacity: 1;
}

.WrapperRealTimeImage {
  position: relative;
}

.imageContainer {
  position: relative;
}

.realTimePhoto .imageContainer>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imageContainer>.reCaptureImage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

/** For recapture  */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  transition: background 0.5s ease;
}

.realTimePhoto .imageContainer:hover .overlay {
  display: block;
  background: rgba(0, 0, 0, 0.3);
}

.realTimePhoto .imageContainer:hover .reCaptureImage {
  opacity: 1;
}

/** lowerForm */
.lowerForm {
  display: flex;
  flex-direction: row;
  column-gap: 3rem;
  flex-wrap: wrap;
  margin-top: 1rem;
}

.tabPanel {
  background: #ffffff;
  border-radius: 8px;
}

.DateAndDistrictWrapper {
  display: flex;
  column-gap: 1rem;
}

/** S  */
.wrapperDiv {
  margin-bottom: 2.6rem;
}

.halfDiv {
  margin-right: 5rem;
  display: inline-block;
  vertical-align: top;
  width: 21.45%;
}

.halfDiv>label:first-child {
  font-size: 1.5rem;
}

.halfDiv:last-child {
  margin-right: 0rem !important;
}

.title p {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 2rem;
}

.subtitle {
  font-size: 1.6rem;
  margin-bottom: 1rem;
}

.textarea {
  resize: none;
  border: 1px solid #d0d5dd !important;
  border-radius: 0.4rem;
  width: -webkit-fill-available;
  padding: 1rem 1.4rem;
  font-size: 1.4rem;
  outline: none;
  margin-top: 0.6rem;
}

textarea:focus {
  border: 1px solid #d6bbfb !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #f4ebff;
}

.buttonAndRadioWrapper>p:last-child {
  color: red;
  font-size: 1.2rem;
  margin: 0;
}

.realTimePhoto>div>.addPhoto {
  width: 100%;
  height: 100%;
  background: #00000021;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
}

.realTimePhoto>div {
  width: 110px;
  height: 120px;
}

/* Hor Header */
.headerWrapper {
  padding: 12px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border-bottom: 0.2rem solid #EAECF0; */
}

.allApplicantsText,
.allStudentsText {
  color: #101828;
  /* width: 100%; */
  display: inline-block;
  font-size: 3rem;
  font-family: Inter-Regular;
  font-weight: 500;
  line-height: 3.8rem;
  vertical-align: middle;
}

.allStudentsText {
  cursor: pointer;
}

.lastupdatedAt {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 0.2rem solid #eaecf0;
  padding: 0rem 4rem 1rem 4rem;
}

.lastUpdatedAtText {
  display: inline-block;
  color: #667085;
  font-size: 1.4rem;
  font-family: Inter-Medium;
  margin-right: 1rem;
}

.wrapperCompanyLogo {
  display: inline-block;
  width: 50%;
  text-align: left;
  margin-left: 2rem;
  vertical-align: middle;
}

.poweredByText {
  display: inline-block;
  font-family: Inter-Medium;
  line-height: 2rem;
  font-size: 1.2rem;
  color: #1976d2;
  vertical-align: middle;
}

.logo {
  width: 6rem;
  height: auto;
  object-fit: contain;
  vertical-align: middle;
}

.dateText {
  font-size: 1.4rem;
  font-family: Inter-Medium;
  color: #667085;
  line-height: 2rem;
}

.headerWrapper>div:last-child {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
}

.headerWrapper .breadcrumb {
  display: flex;
  flex-direction: row;
  /* column-gap: 1.5rem; */
  align-items: center;
  font-size: 2.5rem;
}

.headerWrapper .breadcrumb>img:first-child {
  cursor: pointer;
  margin-right: 1.5rem;
}

.headerWrapper .breadcrumb>img:nth-child(2) {
  width: 0.7rem;
}

.headerWrapper .breadcrumb>.breadcrumbArrowImg {
  width: 0.7rem;
  margin-right: 1.5rem;
}

.headerWrapper .breadcrumb>div {
  margin-right: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.TabPanelSaveButtonWrapper {
  display: flex;
  flex-direction: row-reverse;
}

.heightItemsWrapper {
  display: flex;
  column-gap: 3rem;

  flex-wrap: wrap;
}

.heightItems {
  margin-bottom: 1.5rem;
  flex: 1;
  min-width: 184px;
}

.heightItems>label:first-child {
  font-size: 1.5rem;
}

.lowerStatusChip {
  display: none;
  text-align: right;
  flex: 1;
  width: 100%;
}

.captureCard {
  padding: 0rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: 1rem; */
}
.videoCamera,
.capturedImg {
    width: 16rem;
}
.cameraIcon {
  position: absolute !important;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(255, 255, 255, 0.7) !important;
}



/* * Media Query */
@media screen and (max-width: 1300px) {

  /* .upperFormWrapper > div {
    width: calc(50% - 1.5rem);
  } */
  .lowerForm>.leftContainer>div {
    width: calc(50% - 1.5rem);
  }
}

@media screen and (max-width: 1000px) {
  .formAndPhotoWrapper {
    flex-direction: row-reverse;
  }

  .lowerForm {
    flex-direction: column;
  }

  .formAndPhotoWrapper>div:first-child {
    order: 2;
    margin-top: 10px;
  }

  .formAndPhotoWrapper>div:last-child {
    order: 1;
  }
}

@media screen and (max-width: 950px) {
  .headerWrapper>div:last-child {
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .headerWrapper>div:last-child>div {
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 1666px) {
  .headerWrapper {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 1.5rem;
    font-size: 2rem !important;
  }

  .siteWrapper {
    padding: 2rem;
    height: calc(100% - 4rem);
  }

  .headerButtonWrapper {
    width: 100%;
  }

  .headerButtonWrapper>div {
    flex: 1;
  }

}

@media screen and (max-width: 700px) {
  .formAndPhotoWrapper {
    flex-direction: column-reverse;
  }

  .lowerForm {
    margin-top: 15px;
  }

  .halfDiv {
    width: calc(50% - 1.5rem);
    margin-right: 3rem;
  }

  .halfDiv {
    width: calc(50% - 1.5rem);
    margin-right: 3rem;
  }

  .rightContainer>div>div:last-child>div {
    width: auto;
  }
}

@media screen and (max-width: 710px) {
  .pageWrapper {
    padding: 2rem;
    height: calc(100% - 24.1rem);
  }
}

@media screen and (max-width: 565px) {
  .formWrapper {
    flex-direction: column;
  }

  .siteWrapper {
    padding: 1rem;
    height: calc(100% - 2rem);
  }

  .headerStatusChip {
    display: none;
  }

  .lowerStatusChip {
    display: block;
  }
}

@media screen and (max-width: 465px) {
  .headerWrapper {
    padding: 1rem;
  }

  .headerWrapper>div:last-child {
    flex-wrap: wrap;
    row-gap: 1rem;
  }

  .tabPanel {
    padding: 0 !important;
  }
}

@media screen and (max-width: 400px) {
  .upperFormWrapper>div {
    width: 100%;
  }

  .lowerForm>.leftContainer>div {
    width: 100%;
  }

  .headerWrapper .breadcrumb {
    font-size: 2rem;
  }

  .headerWrapper .breadcrumb>div:last-child {
    margin-right: 0rem;
  }
}

@media screen and (max-width: 388px) {
  .halfDiv {
    width: 100%;
    margin: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 388px) {
  .photosWrapper>div:first-child>img {
    /* width: calc(50% - 5px); */
  }
}