.modalWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: min(800px,90%);
  background: #ffffff;
  border-radius: 0.6rem;
  box-shadow: 0px 7px 14px rgba(64, 68, 82, 0.08);
  max-height: 90%;
  display: flex;
  flex-direction: column;
}
/* * header */

.modalWrapper > .header {
  display: flex;
  /* justify-content: space-between; */
  padding: 2rem;
  border-bottom: 1px solid #eaecf0;
}
.modalWrapper > .header h1 {
  margin: 0 1rem;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #344054;
}
.modalWrapper > .header img:first-child {
  width: 1.3rem;
}
.modalWrapper > .header img:last-child {
  position: absolute;
  right: 1.3rem;
  top: 1.3rem;
  width: 1.2rem;
}

.mainContainer {
  background: #f9fafb;
  padding: 2rem;
  border-bottom: 1px solid #eaecf0;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

/* *Own */

.uploadFileWrapper {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}
.uploadFileWrapper > img {
  cursor: pointer;
}
.uploadFileWrapper > p {
  margin: 0;
  margin-top: 2rem;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #344054;
}

.zipCrossIconButton {
  padding: 1rem !important;
  cursor: pointer !important;
  position: absolute !important;
  top: -8px;
  right: -7px;
  border-radius: 50% !important;
  box-shadow: 0px 0px 6px 1px rgb(0 0 0 / 40%);
  background: #ffffff;
}


/* !Own */

/* Footer */
.footer {
  padding: 2rem;
  display: flex;
  justify-content: flex-end;
}
.footer > div {
  margin: 0 0.5rem;
}

