.pageWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.pageWrapper > .leftPageWrapper, .pageWrapper .rightPageWrapper {
  /* flex: 1; */
}

.leftPageWrapper {
  background: #fcfcfd;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  display: flex;
  flex-direction: column;
  border-right: solid 1px rgba(224, 224, 224, 1);
  width: 40%;
}

.leftPageWrapperHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 3rem;
  border-bottom: 1px solid #EAECF0;
}
.viewProformaBasicDetails {
  padding: 2rem 2rem 0 2rem;
}


.rightPageWrapper {
  /* display: flex; */
  /* flex-direction: column; */
  width: 60%;
}




/** Data Grid */
.imageCellWrapper {
  display: flex;
  column-gap: 12px;
}
.imageCellWrapper > img{
  width: 4rem;
  height: 4rem;
  object-fit: contain;
}
.imageCellWrapper p {
  margin: 0;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #667085;
}
.imageCellWrapper p:first-child {
  color: #101828;
  font-weight: 500;
}


.doneButton {
  padding: 2px 10px;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #039855;
  background: #d1fadf;
  border-radius: 16px;
  /* cursor: pointer; */
  text-transform: capitalize;
}
.pendingButton {
  padding: 2px 10px;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #dc6803;
  background: #fef0c7;
  border-radius: 16px;
  cursor: pointer;
  text-transform: capitalize;
}
.qualifiedButton {
  padding: 2px 10px;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #039855;
  background: #d1fadf;
  border-radius: 16px;
  cursor: pointer;
  text-transform: capitalize;
}
.verifyButton{
  padding: 2px 10px;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #ffffff;
  background: #6941C6;
  border-radius: 16px;
  cursor: pointer;
  text-transform: capitalize;
}