.mainTabPanelWrapper {
    background: #ffffff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
      0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .mainTabPanelHeader {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* padding: 2rem; */
    padding: 2rem 2rem 1rem 2rem;
  }
  
  .mainTabPanelHeader > div:first-child {
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2rem;
    color: #667085;
    margin-bottom: 1rem;
  }
  .mainTabPanelHeader > div:first-child > p {
    margin: 0;
  }
  .mainTabPanelHeader > div:first-child > p:first-child {
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.8rem;
    color: #101828;
  }
  .mainTabPanelHeader > div:last-child > div {
    margin: 0 0.5rem;
    margin-bottom: 1rem;
    flex: 1;
  }
  .mainTabPanelHeader > div:last-child {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    /* column-gap: 1.5rem; */
  }
  
  .searchAndFilterWrapper {
    border: solid 1px #eaecf0;
    padding: 1.2rem 1.6rem 0.5rem 1.6rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .searchAndFilterWrapper > div:first-child {
    flex-grow: 5;
    margin-bottom: 0.5rem;
    margin-right: 1rem;
  }
  .searchAndFilterWrapper > div:last-child {
    display: flex;
    margin-bottom: 0.5rem;
    flex-grow: 5;
    align-items: center;
    justify-content: space-between;
    /* column-gap: 12px; */
  }
  .searchAndFilterWrapper > div:last-child > div:first-child {
    /*? for search */
    /* min-width: calc(100% - 10rem); */
    /* width: calc(100% - 11rem); */
    margin-right: 1rem;
  }
  /* ? table */
  .examCenterWrapper {
    /* height: calc(100vh - 44rem); */
    width: 100%;
    flex: 1;
  }
  
  /* * Applicants Tab  ~~~~~~~~~~~~~~~~~~~~~~~~~~ 👨‍🎓👨‍🎓👨‍🎓👨‍🎓👨‍🎓👨‍🎓👨‍🎓👨‍🎓 */
  
  .imageCellWrapper {
    display: flex;
    column-gap: 12px;
  }
  .imageCellWrapper p {
    margin: 0;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2rem;
    color: #667085;
  }
  .imageCellWrapper p:first-child {
    color: #101828;
    font-weight: 500;
  }
  .doneButton {
    padding: 2px 10px;
    font-size: 1.4rem;
    line-height: 2rem;
    color: #039855;
    background: #d1fadf;
    border-radius: 16px;
    cursor: pointer;
    text-transform: capitalize;
  }
  .pendingButton {
    padding: 2px 10px;
    font-size: 1.4rem;
    line-height: 2rem;
    color: #dc6803;
    background: #fef0c7;
    border-radius: 16px;
    cursor: pointer;
    text-transform: capitalize;
  }
  .qualifiedButton {
    padding: 2px 10px;
    font-size: 1.4rem;
    line-height: 2rem;
    color: #039855;
    background: #d1fadf;
    border-radius: 16px;
    cursor: pointer;
    text-transform: capitalize;
  }