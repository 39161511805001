.modalWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  min-width: 275px;
  background: #ffffff;
  font-size: 1.4rem;
  border-radius: 1.2rem;
}
.wrapperProgress {
  width: calc(100% - 4rem);
  height: 50%;
  padding: 2rem;
}
.buttonWrapper {
  display: inline-block;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 0.8rem;
  width: 10%;
  cursor: pointer;
  padding: 1rem 1.6rem;
  text-align: center;
}

.PhotosTableWrapper {
  border-spacing: 0;
  background: transparent;
}
.PhotosTableWrapper th:first-of-type{
  border-left: 1px solid #667085;
}
.PhotosTableWrapper th{
  border: unset;
  padding: 1rem;
  border-right: 1px solid #667085;
}
.PhotosTableWrapper tr:first-of-type > th{
  border-top: 1px solid #667085;
}
.PhotosTableWrapper tr > th{
  border-bottom: 1px solid #667085;
}