.modalWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background: #ffffff;
  }
  /* * header */
  .modalWrapper > .header {
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    border-bottom: 1px solid #eaecf0;
  }
  .modalWrapper > .header h1 {
    margin: 0;
  }
  .modalWrapper > .header img:last-child {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
  }
  
  .mainContainer {
    background: #f9fafb;
    padding: 2rem;
    border-bottom: 1px solid #eaecf0;
    position: relative;
  }
  
  .footer {
    padding: 2rem;
    display: flex;
    justify-content: flex-end;
    column-gap: 1rem;
  }
  