.pageWrapper {
    background: #f9fafb;
    padding: 0rem 3.2rem 2.2rem 3.2rem;
    height: calc(100% - 2.2rem);
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  /* * For Header */
  .headerWrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .headingWrapper {
    flex-grow: 1;
    margin-bottom: 0.5rem;
  }
  .headingWrapper > p:first-child {
    font-weight: 500;
    font-size: 3rem;
    line-height: 3.8rem;
    color: #101828;
    margin: 0;
  }
  .headingWrapper > p:last-child {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: #667085;
    margin: 0;
  }
  .headerButtonWrapper {
    display: flex;
    flex-grow: 0;
    flex-wrap: wrap;
  }
  .headerButtonWrapper > div {
      margin-bottom: 0.5rem;
      flex: 1;
  }
  .headerButtonWrapper > div:last-child {
    margin-left: 1rem;
  }
  
  
  /* ! Breaker */
  .breaker {
    height: 1px;
    background: #eaecf0;
    margin: 2rem 0;
  }
  
  /* * Main Container */
  .mainTabPanelWrapper {
      background: #ffffff;
      border: 1px solid #eaecf0;
      box-sizing: border-box;
      box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
        0px 2px 4px -2px rgba(16, 24, 40, 0.06);
      border-radius: 8px;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    .mainTabPanelHeader {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      /* padding: 2rem; */
      padding: 2rem 2rem 1rem 2rem;
    }
    
    .mainTabPanelHeader > div:first-child {
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: 2rem;
      color: #667085;
      margin-bottom: 1rem;
    }
    .mainTabPanelHeader > div:first-child > p {
      margin: 0;
    }
    .mainTabPanelHeader > div:first-child > p:first-child {
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 2.8rem;
      color: #101828;
    }
    .mainTabPanelHeader > div:last-child > div {
      margin: 0 0.5rem;
      margin-bottom: 1rem;
      flex: 1;
    }
    .mainTabPanelHeader > div:last-child {
      display: flex;
      flex-wrap: wrap;
      /* column-gap: 1.5rem; */
    }
    
    .searchAndFilterWrapper {
      border: solid 1px #eaecf0;
      padding: 1.2rem 1.6rem 0.5rem 1.6rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .searchAndFilterWrapper > div:first-child {
      flex-grow: 5;
      margin-bottom: 0.5rem;
      margin-right: 1rem;
    }
    .searchAndFilterWrapper > div:last-child {
      display: flex;
      margin-bottom: 0.5rem;
      flex-grow: 5;
      align-items: center;
      /* column-gap: 12px; */
    }
    .searchAndFilterWrapper > div:last-child > div:first-child {
      /*? for search */
      /* min-width: calc(100% - 10rem); */
      width: calc(100% - 11rem);
      margin-right: 1rem;
    }
    /* ? table */
    .examCenterWrapper {
      /* height: calc(100vh - 44rem); */
      flex: 1;
      width: 100%;
    }
  
  /* ? FOR iMAGE & EMAIL DataGrid */
    .imageCellWrapper {
      display: flex;
      column-gap: 12px;
    }
    .imageCellWrapper p {
      margin: 0;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 2rem;
      color: #667085;
    }
    .imageCellWrapper p:first-child {
      color: #101828;
      font-weight: 500;
    }