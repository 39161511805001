.examContainer {
    background-color: #f9fafb;
    flex: 1;
}
.titleContainer {
    padding-bottom: 2.4rem;
    border-bottom: 1px solid #eaecf0;
}
.firstRow {
    /* margin-bottom: 2.4rem; */
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.secondRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.titleWrapper {
    vertical-align: top;
}
.btnWrapper {
    vertical-align: top;
    text-align: right;
}
.title {
    font-weight: 500;
    font-size: 3rem;
    line-height: 3.8rem;
    color: #101828;
    margin-bottom: 0.4rem;
}
.subtitle {
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: #667085;
}
/* .uploadBtn {
    margin-right: 1.2rem;
} */
.filterBtn {
    vertical-align: top;
}
.examWrapper {
    margin-top: 2.4rem;
    display: flex;
    flex-wrap: wrap;
    min-height: calc(100% - 25rem);
}
.paddingDiv {
    padding: 1.5rem;
}
.examCard {
    cursor: pointer;
}
.examCardFirstRow {
    border-bottom: 1px solid #eaecf0;
}
.imageWrapper {
    margin-bottom: 2rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.imageSection {
    vertical-align: top;
}
.imageSection > img {
    width: 8rem;
    height: 8rem;
}
.statusWrapperGreen {
    background: #ecfdf3;
    border-radius: 1.6rem;
    padding: 0.2rem 0.8rem;
    text-align: center;
    display: flex;
    align-items: center;
}
.statusWrapperRed {
    background: #fff0f5;
    border-radius: 1.6rem;
    padding: 0.2rem 0.8rem;
    text-align: center;
    display: flex;
    align-items: center;
}
.statusTextGreen {
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.8rem;
    text-align: center;
    color: #027a48;
    vertical-align: middle;
    margin-left: 0.7rem;
}
.searchBarWrapper {
    /* width: 30%; */
}
.statusTextRed {
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.8rem;
    text-align: center;
    color: red;
    vertical-align: middle;
    margin-left: 0.7rem;
}
.info {
    display: flex;
    justify-content: space-between;
}

.label {
    font-size: 1.4rem;
    line-height: 2rem;
    color: #667085;
}
.examName {
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: #101828;
    font-weight: bold;
}
.nameWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.examDetailsWrapper:first-child {
    margin-left: 0rem;
}
.inputLabel {
    font-size: 1rem;
    line-height: 1.8rem;
    color: #667085;
}
.inputValue {
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2rem;
    color: #101828;
}
