.wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    width: 50%;
    height: 50%;
    border-radius: 0.8rem;
    padding: 2rem;
    overflow-y: auto;
}
.modalHeader {
    display: inline-block;
    width: 100%;
    margin-bottom: 2rem;
    font-size: 1.4rem;
    line-height: 2rem;
    font-family: 'Inter-Medium';
}
.modalTitle,
.modalClose {
    display: inline-block;
    width: 50%;
}
.modalClose {
    text-align: right;
    cursor: pointer;
}
.modalBody {
    display: inline-block;
    width: 100%;
    text-align: center;
}
.examObj {
    display: inline-block;
    width: calc(33.33% - 2rem);
    margin-right: 2rem;
    margin-bottom: 2rem;
}
.img {
    display: inline-block;
    margin-bottom: 1rem;
}
.img > img {
    width: 15rem;
}
.examName {
    display: inline-block;
    font-size: 1.4rem;
    line-height: 2rem;
}
